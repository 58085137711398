import React from 'react';
import { Button, Modal } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import logoSAE from '../images/sae.svg';
import logoAudio from '../images/audio.svg';
import logoMusic from '../images/music.svg';
import logoFilm from '../images/film.svg';
import logoVFX from '../images/vfx.svg';
import logoGame from '../images/game.svg';
import logoWeb from '../images/web.svg';
import logoMPP from '../images/mpp.svg';

const schedules = [
	{
		logo: [logoAudio],
		title: ['Audio Production'],
		link: 'https://sae.zoom.us/j/4187810962',
		schedules: ['11h15', '14h15'],
		questions: ['13h à 14h', '16h à 17h']
	},
	{
		logo: [logoMusic],
		title: ['Music Business'],
		link: 'https://sae.zoom.us/j/99352178588',
		schedules: ['13h15', '15h15'],
		questions: ['11h15 à 12h15', '14h30 à 15h', '16h15 à 17h']
	},
	{
		logo: [logoFilm],
		title: ['Digital Film Production'],
		link: 'https://sae.zoom.us/j/94189752530',
		schedules: ['13h15', '15h15'],
		questions: ['11h15 à 12h15', '14h30 à 15h', '16h15 à 17h']
	},
	{
		logo: [logoGame, logoVFX],
		title: ['Game Art Animation', 'Visual Effects Animation'],
		link: 'https://sae.zoom.us/j/3657838273',
		schedules: ['13h15', '15h15'],
		questions: ['11h15 à 12h15', '14h30 à 15h', '16h15 à 17h']
	},
	{
		logo: [logoGame],
		title: ['Games Programming'],
		link: 'https://sae.zoom.us/j/94705011352',
		schedules: ['11h15', '14h15'],
		questions: ['13h à 14h', '15h15 à 17h']
	},
	{
		logo: [logoMPP, logoWeb],
		title: ['Media Production and Publishing', 'Web Development'],
		link: 'https://sae.zoom.us/j/8840189860',
		schedules: ['11h15'],
		questions: ['13h à 17h']
	},
	{
		logo: [logoSAE],
		title: ['Structure et contenu académiques des formations'],
		link: 'https://sae.zoom.us/j/4819698157',
		schedules: ['12h15', '14h15', '16h15'],
		questions: null
	},
	{
		logo: [logoSAE],
		title: ['Bureau des admissions'],
		link: 'https://sae.zoom.us/j/5711536338',
		schedules: ['11h30', '13h', '15h', '16h15'],
		questions: null
	},
];

const List = styled.ul`
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	
	li {
		flex: 1 1 40%;
		margin: 20px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 5px;
		transition: background 0.2s ease-in-out;
		
		& > div {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 20px;
			color: rgba(255, 255, 255, 0.8);
		}
		
		img {
			width: 32px;
			margin-right: 20px;
		}
		
		.list-title {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}
		
		.schedules {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 20px;
			text-align: center;
			
			.schedule-box {
				flex: 0 0 100%;
				color: #f68b33;
				padding: 0 10px;
				
				h4 {
					color: #f68b33;
				}
			}
			
			.question-box {
				flex: 0 0 100%;
				padding: 0 10px;
				color: rgba(255, 255, 255, 0.85);
			}
		}
		
		&:hover {
			background: rgba(255, 255, 255, 0.15);
		}
	}
	
	@media (min-width: 768px) {
		li {
			.schedules {
				flex-wrap: nowrap;
				
				.schedule-box, .question-box {
					flex: 1 0 auto;
				}
			}
		}
	}
	
	@media (min-width: 960px) {
		li {
			flex: 1 1 30%;
			
			.schedules {
				.schedule-box, .question-box {
					flex: 1 0 auto;
				}
			}
		}
	}
`;

const Schedules = ({ visible, onClose }) => (
	<Modal
		title="Horaires de la journée portes ouvertes online"
		visible={visible}
		onCancel={onClose}
		footer={null}
		centered={true}
		bodyStyle={{ padding: 0 }}
	>
		<List>
			{schedules.map((item, index) => (
				<li key={index}>
					<div>
						<div>
							{item.logo.map((logo, i) => (
								<div key={i} className="list-title">
									<img src={logo} alt={item.title[i]} />
									<h3 style={{ fontSize: '1.1rem', fontWeight: 300, margin: 0 }}>{item.title[i]}</h3>
								</div>
							))}
						</div>

						<div className="schedules">
							<div className="schedule-box">
								<h4>Présentations</h4>
								{item.schedules.map((schedule, i) => (
									<div key={i} style={{ fontSize: '1.3rem', textAlign: 'center', margin: '0 0 20px 0' }}>{schedule}</div>
								))}
							</div>
							{item.questions !== null && (
								<div className="question-box">
									<h4>Questions/Réponses</h4>
									{item.questions.map((question, i) => (
										<div key={i} style={{ fontSize: '1.3rem', textAlign: 'center', margin: '0 0 20px 0' }}>{question}</div>
									))}
								</div>
							)}
						</div>

						<Button
							type="default"
							ghost={true}
							block={true}
							icon={<ExportOutlined />}
							style={{ marginTop: 20 }}
							href={item.link}
							target="_blank"
							rel="noopener nofollow"
						>
							Rejoindre la séance
						</Button>
					</div>
				</li>
			))}
		</List>
	</Modal>
);

export default Schedules;