import React, { createContext, useEffect, useState } from 'react';
import Schedules from './schedules';
import '../theme/style.less';

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
	const [ schedulesModal, setSchedulesModal ] = useState(false);

	useEffect(() => {
		const lottie = document.createElement('script');
		lottie.src = 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.8/lottie.min.js';
		document.body.appendChild(lottie);
	}, []);

	return (
		<AppContext.Provider value={{ setSchedulesModal }}>
			{children}

			<Schedules
				visible={schedulesModal}
				onClose={() => setSchedulesModal(false)}
			/>
		</AppContext.Provider>
	);
};

export default ({ children }) => {
	return (
		<AppContextProvider>
			{children}
		</AppContextProvider>
	);
};